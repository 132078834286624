import { useThree, useFrame } from "@react-three/fiber";
import { useState, useEffect } from "react";
import { Vector3 } from "three";
import { Settings } from "../../../schema/types";

export function Camera2({
  playing,
  settings,
}: {
  playing: boolean
  settings: Settings['camera']
}) {
  const {
    gl, // WebGL renderer
    scene, // Default scene
    camera, // Default camera
  } = useThree()
  const [lat, setLat] = useState(0)
  const [stopLat, setStopLat] = useState(false)

  useFrame(({ clock }) => {
    setLat(clock.getElapsedTime())
  })

  useEffect(() => {
    if (playing) {
      setStopLat(true)
      camera.zoom = settings.zoom
      camera.position.lerp(
        new Vector3(settings.z || 0, settings.y, settings.x),
        0.05,
      )
      camera.lookAt(0, 5, 0)
      camera.updateProjectionMatrix()
    } else {
      camera.lookAt(0, 5, 0)
      camera.position.lerp(
        new Vector3(Math.sin(lat) * 2 + settings.lobby?.y || 30, Math.sin(lat) * 2 + settings.lobby?.x || 30, settings.lobby?.z || 30),
        0.05,
      )
      camera.updateProjectionMatrix()
    }
  }, [lat, playing])
  return null
}

export default Camera2